<template>
  <v-container>
    <print-facture v-if="showShowModal && printer === 'ticket'"/>
    <print-facture-letter v-if="showShowModal && printer === 'letter'"/>
    <new-refound v-if="showNewModal" @reloadData="window.location.reload()"/>
    <app-data-table
      :id="id"
      :select-data="selectData"
      :headers="getTableColumns"
      csv-filename="SaleProducts"
      :manager="'sales'"
      :items="saleAccepted"
      :view-new-button="false"
      :options="vBindOption"
      :sort-by="['no_facture']"
      :sort-desc="[false, true]"
      :viewShowFilterDate="true"
      multi-sort
      :multi-select=true
      :is-loading="isTableLoading"
      @reload-data="reloadData($event)"
    >
      <template v-slot:[`item.no_facture`]="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <b>
                <v-icon
                  style="color: red"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  @click="openPrintModal('ticket',item.id)"
                  v-on="on"
                >
                  mdi-printer
                </v-icon>
              </b>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.report.print_ticket") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <b>
                <v-icon
                  style="color: #1a5dd6"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  @click="openPrintModal('letter', item.id)"
                  v-on="on"
                >
                  mdi-printer-settings
                </v-icon>
              </b>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.report.print_letter") }}</span>
          </v-tooltip>
        </template>
        {{ item.no_facture }}
      </template>
      <template v-slot:[`item.state_text`]="{ item }">
        <template v-if="item.state !== 'preform' && item.state !== 'cancelled'">
          <v-autocomplete
            v-model="item.state"
            chips
            :items="getLocalStates(item)"
            item-text="text"
            item-value="value"
            :style="{ width: '160px' }"
            @input="changeState(item)"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.item.value"
                @click="data.select"
              >
                <i :style="'color: ' + data.item.color">
                  <v-icon left>
                    {{ data.item.icon }}
                  </v-icon>
                  {{ data.item.text }}</i>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template
                v-if="typeof data.item !== 'object'"
              >
                <v-list-item-content
                  v-text="data.item"
                />
              </template>
              <template v-else>
                <v-list-item-icon>
                  <v-icon
                    left
                    :style="'color: ' + data.item.color"
                  >
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :style="
                          'color: ' + data.item.color
                        "
                  >
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </template>
        <span :style="item.state === 'cancelled' ? 'color: red' : 'color: green'" v-else>{{ item.state_text }}</span>
      </template>
      <template
        v-slot:[`item.type_order`]="{ item }"
      >
        <span v-if="item.type_order"> {{ item.type_order.name }}</span>
        <span v-else> --- </span>
      </template>
      <template
        v-slot:[`item.totalPrice`]="{ item }"
      >
        <v-tooltip v-if="item.discounts.length > 0 || item.taxes.length > 0">
          <template
            v-slot:activator="{ on, attrs }"
          >
            <b>
              <v-icon
                style="color: red"
                class="mr-2"
                small
                v-bind="attrs"
                v-on="on">
                mdi-information
              </v-icon>
            </b>
            <span> {{ parseFloat(item.totalPrice).toFixed(2) + ' ' + getCurrency }}</span>
          </template>
          <facture :sale="item" :sub-total="item.subTotal" :total-tax="item.totalTax"
                   :total-discount="item.totalDiscount" :total-price="item.totalPrice"/>
        </v-tooltip>
        <span v-else> {{ parseFloat(item.totalPrice).toFixed(2) + ' ' + getCurrency }}</span>
      </template>
      <template
        v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
      >
        <v-btn
          v-if="item.sale_article_shops.length > 0"
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0 0 0 0"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <!--th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.articles.ref") }}
                </th-->
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.firstName") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.variants.cant") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.articles.price") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.variants.total_price") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.articles.new_inventory") }}
                </th>
                <template
                  v-if="item.state === 'accepted'"
                >
                  <th class="text-left">
                    {{
                      $vuetify.lang.t(
                        "$vuetify.actions.actions"
                      )
                    }}
                  </th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="sale_article_shop in item.sale_article_shops"
                :key="sale_article_shop.id"
              >
                <template v-if="sale_article_shop.article_shops">
                  <!--td-->
                  <!--template v-if="sale_article_shop.refunds.length > 0">
                    <v-tooltip right>
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <b><v-icon
                          v-if=" sale_article_shop.cant > 0 "
                          style="color: red"
                          class="mr-2"
                          small
                          v-bind="attrs"
                          v-on="on">
                          mdi-information
                        </v-icon></b>
                      </template>
                      <template>
                        <detail-refund
                          :sale-article-shop="sale_article_shop"
                          :currency="`${getCurrency}`"
                        />
                      </template>
                    </v-tooltip>
                  </template-->
                  <!--span
                    v-if="sale_article_shop.article_shops.article">{{
                      sale_article_shop.article_shops.article.ref
                    }}</span>
                </td-->
                  <template>
                    <td v-if="sale_article_shop.article_shops.article">
                      {{ sale_article_shop.article_shops.article.nameComplete }}
                    </td>
                    <td v-else>{{ sale_article_shop.article_shops.article_deleted.nameComplete }}</td>
                  </template>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <b>
                          <v-icon
                            v-if=" sale_article_shop.cantRefund.cant > 0"
                            style="color: red"
                            class="mr-2"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </b>
                      </template>
                      <span>
                      {{
                          $vuetify.lang.t("$vuetify.menu.refund") + ": " + parseFloat(sale_article_shop.cantRefund.cant).toFixed(2)
                        }}
                    </span>
                    </v-tooltip>
                    {{ parseFloat(sale_article_shop.cant).toFixed(2) }}
                    {{ sale_article_shop.um ? sale_article_shop.um.name : '' }}
                  </td>
                  <td>
                    <v-tooltip
                      v-if="parseFloat(sale_article_shop.article_shops.price).toFixed(2) !== parseFloat(sale_article_shop.price).toFixed(2)"
                      top>
                      <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >{{
                              `${parseFloat(sale_article_shop.price).toFixed(2) + getCurrency}`
                            }}
                          </span>
                      </template>
                      <span>
                        {{ $vuetify.lang.t('$vuetify.sale.update_price') }}: {{ `${sale_article_shop.article_shops.price + ' ' + getCurrency}` }}
                      </span>
                    </v-tooltip>
                    <span v-else> {{ `${sale_article_shop.price + ' ' + getCurrency}` }} </span>
                  </td>
                  <td>
                    <template
                      v-if="sale_article_shop.article_shops.article.taxes.length > 0 || sale_article_shop.discounts.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <b>
                            <v-icon
                              :color="sale_article_shop.cantRefund.money > 0 ? 'red': 'primary'"
                              class="mr-2"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </b>
                        </template>
                        <template>
                          <detail-article-price
                            :article="sale_article_shop"
                            :currency="
                                  user.company
                                    .currency
                                "
                          />
                          <span v-if="sale_article_shop.cantRefund.money > 0">
                                {{
                              $vuetify.lang.t("$vuetify.menu.refund") + ": " +
                              `${sale_article_shop.cantRefund.money + ' ' + getCurrency}`
                            }}</span>
                        </template>
                      </v-tooltip>
                    </template>
                    <v-tooltip top v-if="sale_article_shop.cantRefund.money > 0">
                      <template v-slot:activator="{ on, attrs }">
                        <b>
                          <v-icon
                            :color="sale_article_shop.cantRefund.money > 0 ? 'red': 'primary'"
                            class="mr-2"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </b>
                      </template>
                      <span>{{ $vuetify.lang.t("$vuetify.menu.refund") + ": " +
                      `${parseFloat(sale_article_shop.cantRefund.money).toFixed(2) + ' ' + getCurrency}`
                        }}</span>
                    </v-tooltip>
                    <span>{{
                        `${parseFloat(sale_article_shop.totalPrice).toFixed(2) + ' ' + getCurrency}`
                      }}</span>
                  </td>
                  <td>
                    <template v-if="sale_article_shop.article_shops.inventory > 0">
                      {{ sale_article_shop.article_shops.inventory }}
                    </template>
                    <template v-else>
                      <i style="color: red">
                        <v-icon
                          style="color: red"
                        >
                          mdi-arrow-down-bold-circle
                        </v-icon>
                        {{ parseFloat(sale_article_shop.article_shops.inventory).toFixed(2) }}
                      </i>
                    </template>
                  </td>
                  <template v-if="item.state === 'accepted'">
                    <td>
                      <template v-if="item.state === 'accepted'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <b>
                              <v-icon
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="refundArticle(item, sale_article_shop)"
                              >
                                mdi-undo
                              </v-icon>
                            </b>
                          </template>
                          <span>{{ $vuetify.lang.t("$vuetify.actions.refund") }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip top>
                          <template
                            v-slot:activator="{
                                  on,
                                  attrs
                                }"
                          >
                            <b>
                              <v-icon
                                v-if="
                                    sale_article_shop.cant >
                                      0
                                  "
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="
                                    attrs
                                  "
                                v-on="
                                    on
                                  "
                                @click="
                                    cancelProductPreform(
                                      item, sale_article_shop.article_shops
                                    )
                                  "
                              >
                                mdi-cancel
                              </v-icon>
                            </b>
                          </template>
                          <span>{{
                              $vuetify.lang.t(
                                "$vuetify.actions.refund"
                              )
                            }}</span>
                        </v-tooltip>
                      </template>
                    </td>
                  </template>
                </template>
                <template v-else-if="sale_article_shop.article_shops_deleted">
                  <td>
                    <template v-if="sale_article_shop.refunds.length > 0">
                      <v-tooltip right>
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <b>
                            <v-icon
                              v-if=" sale_article_shop.cant > 0 "
                              style="color: red"
                              class="mr-2"
                              small
                              v-bind="attrs"
                              v-on="on">
                              mdi-information
                            </v-icon>
                          </b>
                        </template>
                        <template>
                          <detail-refund
                            :sale-article-shop="sale_article_shop"
                            :currency="`${getCurrency}`"
                          />
                        </template>
                      </v-tooltip>
                    </template>
                    {{
                      sale_article_shop.article_shops_deleted.article_deleted.ref
                    }}({{ $vuetify.lang.t("$vuetify.articles.remove_inventory") }})
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    {{ sale_article_shop.article_shops_deleted.article_deleted.nameComplete }}
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <b>
                          <v-icon
                            v-if=" sale_article_shop.cantRefund > 0"
                            style="color: red"
                            class="mr-2"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </b>
                      </template>
                      <span>
                    {{
                          $vuetify.lang.t("$vuetify.menu.refund") + ": " + parseFloat(sale_article_shop.cantRefund).toFixed(2)
                        }}
                                                    </span>
                    </v-tooltip>
                    {{ parseFloat(sale_article_shop.cant).toFixed(2) }}
                    {{ sale_article_shop.um ? sale_article_shop.um.name : '' }}
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <v-tooltip
                      v-if="parseFloat(sale_article_shop.article_shops_deleted.price).toFixed(2) !== parseFloat(sale_article_shop.price).toFixed(2)"
                      top>
                      <template v-slot:activator="{ on, attrs }">
                                                      <span
                                                        v-bind="attrs"
                                                        v-on="on"
                                                      >{{
                                                          `${parseFloat(sale_article_shop.price).toFixed(2) + ' ' + getCurrency}`
                                                        }}
                                                      </span>
                      </template>
                      <span>
                                                  {{ $vuetify.lang.t('$vuetify.sale.update_price') }}: {{
                          `${sale_article_shop.article_shops_deleted.price + ' ' + getCurrency}`
                        }}
                                                </span>
                    </v-tooltip>
                    <span v-else>
                                                  {{ `${sale_article_shop.price}` + ' ' + getCurrency }}
                                                </span>
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <template
                      v-if="sale_article_shop.article_shops_deleted.article_deleted.taxes.length > 0 || sale_article_shop.discounts.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <b>
                            <v-icon
                              :color="sale_article_shop.cantRefund.money > 0 ? 'red': 'primary'"
                              class="mr-2"
                              small
                              v-bind="attrs"
                              v-on="on">
                              mdi-information
                            </v-icon>
                          </b>
                        </template>
                        <template>
                          <detail-article-price
                            :article="sale_article_shop"
                            :currency="getCurrency"
                          />
                          <span v-if="sale_article_shop.cantRefund.money > 0">
                            {{
                              $vuetify.lang.t("$vuetify.menu.refund") + ": " +
                              `${sale_article_shop.cantRefund.money + ' ' + getCurrency}`
                            }}</span>
                        </template>
                      </v-tooltip>
                    </template>
                    <span>{{
                        `${parseFloat(sale_article_shop.totalPrice).toFixed(2) + ' ' + getCurrency}`
                      }}</span>
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <template v-if="sale_article_shop.article_shops_deleted.stock > 0">
                      {{ parseFloat(sale_article_shop.article_shops_deleted.stock).toFixed(2) }}
                      {{ sale_article_shop.um ? sale_article_shop.um.name : '' }}
                    </template>
                    <template v-else>
                      <i style="color: red">
                        <v-icon
                          style="color: red"
                        >
                          mdi-arrow-down-bold-circle
                        </v-icon>
                        {{ parseFloat(sale_article_shop.article_shops_deleted.stock).toFixed(2) }}
                      </i>
                    </template>
                  </td>
                  <template v-if="item.state === 'accepted'">
                    <td>
                      <template v-if="item.state === 'accepted'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <b>
                              <v-icon
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="refundArticle(item, sale_article_shop)"
                              >
                                mdi-undo
                              </v-icon>
                            </b>
                          </template>
                          <span>{{ $vuetify.lang.t("$vuetify.actions.refund") }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip top>
                          <template
                            v-slot:activator="{
                                  on,
                                  attrs
                                }"
                          >
                            <b>
                              <v-icon
                                v-if="
                                    sale_article_shop.cant >
                                      0
                                  "
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="
                                    attrs
                                  "
                                v-on="
                                    on
                                  "
                                @click="
                                    cancelProductPreform(
                                      item, sale_article_shop.article_shops
                                    )
                                  "
                              >
                                mdi-cancel
                              </v-icon>
                            </b>
                          </template>
                          <span>{{
                              $vuetify.lang.t(
                                "$vuetify.actions.refund"
                              )
                            }}</span>
                        </v-tooltip>
                      </template>
                    </td>
                  </template>
                </template>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <facture v-if="item.discounts.length > 0 || item.taxes.length > 0"
                   :show-facture="false"
                   :sale="item" :sub-total="item.subTotal"
                   :total-discount="item.totalDiscount"
                   :total-tax="item.totalTax"
                   :total-price="item.totalPrice"/>
        </td>
      </template>
    </app-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewRefound from './NewRefund'
import PrintFactureLetter from '../../sales/commerce/PrintFactureLetter'
import PrintFacture from '../../sales/commerce/PrintFacture'
import DetailArticlePrice from '../../sales/commerce/list-sale/DetailArticlePrice'
import Facture from '../../facture/Facture'

export default {
  name: 'ListSaleRefund',
  data () {
    return {
      filter: null,
      tab: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.titles.list', [
          this.$vuetify.lang.t('$vuetify.menu.vending')
        ])
      }],
      selectData: [],
      loadingData: false,
      id: 'Sale',
      menu: false,
      fav: true,
      message: false,
      hints: true,
      search: '',
      localAccess: {},
      printer: '',
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  components: { NewRefound, PrintFactureLetter, PrintFacture, DetailArticlePrice, Facture },
  computed: {
    ...mapState('sale', ['editSale', 'showEditModal', 'loadData', 'showShowModal', 'saleAccepted',
      'isTableLoading']),
    ...mapState('refund', ['showNewModal']),
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapGetters('auth', ['isManagerIn']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.tax.noFacture'),
          value: 'no_facture',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'state_text',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shop.name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.total_price'),
          value: 'totalPrice',
          select_filter: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('sale', ['toogleNewModal', 'openEditModal', 'openShowModal', 'getSales', 'updateSale',
      'changeStateSale', 'switchLoadData', 'deleteSale', 'multiDelete', 'getAcceptedSalesFilter']),
    ...mapActions('refund', ['openNewModal']),
    reloadData (filter) {
      this.filter = filter
      this.loadInitData()
    },
    openPrintModal (print, id) {
      this.printer = print
      this.openShowModal(id)
    },
    async loadInitData () {
      await this.getAcceptedSalesFilter(this.filter)
      this.switchLoadData(false)
      // this.loadingData = false
    },
    refundArticle (sale, article) {
      if (
        article.cant === article.cantRefund || article.totalPrice === article.totalRefund
      ) {
        this.showMessage(
          this.$vuetify.lang.t('$vuetify.actions.refund', [
            this.$vuetify.lang.t('$vuetify.menu.articles')
          ]),
          this.$vuetify.lang.t('$vuetify.messages.warning_refund_all')
        )
      } else {
        this.openNewModal({ sale, article })
      }
    },
    getLocalStates (item) {
      return item.state === 'open'
        ? [
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.open'
            ),
            value: 'open',
            icon: 'mdi-star-half',
            color: '#4caf50'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.cancelled'
            ),
            value: 'cancelled',
            icon: 'mdi-star-off',
            color: '#ff0000'
          }
        ]
        : [
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.accepted'
            ),
            value: 'accepted',
            icon: 'mdi-star',
            color: '#3f51b5'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.cancelled'
            ),
            value: 'cancelled',
            icon: 'mdi-star-off',
            color: '#ff0000'
          }
        ]
    }
  }
}
</script>

<style scoped>

</style>
